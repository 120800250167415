<div *ngIf="!isMobileScreen; else mobileLayout">

    <div class="bg-color">
        <div class="row">
            <div class="col-sm-4">
                <div class="row">
                    <div class="col-sm-8 d-flex justify-content-start">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp" src="../../../../assets/imgs/svgs/whatsapp.svg"
                                alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.whatsappNumber)" class="font-color click">+55 (71)
                            <strong>3646-3200</strong>
                        </div>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-start">
                    </div>

                    <div class="col-sm-8 d-flex justify-content-start">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp"
                                src="../../../../assets/imgs/svgs/telephone-icon-transparent-bg.svg" alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.phoneNumber)" class="font-color click">+55 (71)
                            <strong>3646-3200</strong>
                        </div>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-start">
                    </div>

                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp"
                                src="../../../../assets/imgs/svgs/instagram-icon-transparent-bg.svg" alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.instagram)" class="font-color click">
                            @<strong>institutoenso</strong></div>
                    </div>
                    <div class="col-sm-6 d-flex justify-content-start">
                    </div>

                    <div class="col-sm-10 d-flex justify-content-start">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp"
                                src="../../../../assets/imgs/svgs/location-icon-transparent-bg.svg" alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.mapGeo)" class="font-color click">
                            <strong>Vitória Boulevard <br> Av. Sete de Setembro, 1839</strong><br>Vitória,
                            Salvador - BA, 40080-002
                        </div>
                    </div>
                    <div class="col-sm-2 d-flex justify-content-start">
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color"><strong>Mapa do site</strong></div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeHome)">HOME</div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeInstituto)">O INSTITUTO</div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeProfissionais)">PROFISSIONAIS
                        </div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeProcedimentos)">PROCEDIMENTOS
                        </div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color click" (click)="redirectToRoute(envi.routePortalPaciente)">PORTAL DO
                            PACIENTE
                        </div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color click" (click)="redirectexternalToRoute(envi.whatsappNumber)">AGENDAMENTO
                        </div>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6 d-flex justify-content-start">
                        <div class="font-color click" (click)="redirectexternalToRoute(envi.whatsappNumber)">CONTATO
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="row">
                    <div class="col-sm-4 d-flex justify-content-end">
                    </div>
                    <div class="col-sm-8 d-flex justify-content-start">
                        <div>
                            <div class="font-color">
                                <div><strong>Responsável Técnico</strong></div>
                                <div>Dr. Almiro Vieira de Melo</div>
                                <div>CRM 15148 | RQE 18782</div>
                            </div>
                        </div>

                        <div>
                            <div class="mb-icon">
                                <img class="bi font-color bi-person"
                                    src="../../../../assets/imgs/svgs/person-icon-transparent-bg.svg" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 d-flex justify-content-end">
                    </div>
                    <div class="col-sm-8 d-flex justify-content-start size-of-col">
                        <div class="font-color">
                            <div><strong>Horário de funcionamento:</strong></div>
                            <div>Segunda a sexta: <strong>07h às 19h</strong></div>
                            <div>Sábado: <strong>07h às 13h</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-color-below">
        <div class="row">
            <div class="col-sm-6 d-flex align-items-center justify-content-center font-color-primary">
                Instituto Enso® - Todos os direitos reservados - CNPJ 29.147.052/0001-77
            </div>
            <div class="col-sm-6 d-flex align-items-center justify-content-center font-color-primary">
                Agência Lutz Brasil | GN Design Studio
            </div>
        </div>
        <div class="row pt">
            <div class="col-sm-6 d-flex align-items-center justify-content-center">
                <div class="font-color click custom-button border-btn"
                    (click)="redirectexternalToRoute(envi.urlCanalDenuncia)">CANAL DE DENÚNCIA</div>
            </div>
            <div class="col-sm-6 d-flex align-items-center justify-content-center">
                <div class="font-color click custom-button border-btn"
                    (click)="redirectexternalToRoute(envi.urlCanalRequisicaoTitular)">CANAL DE REQUISIÇÃO DO TITULAR
                </div>
            </div>
        </div>
    </div>

</div>

<ng-template #mobileLayout>
    <div class="bg-color">
        <div class="row">
            <div class="col-sm-12 mb-2">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp" src="../../../../assets/imgs/svgs/whatsapp.svg"
                                alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.whatsappNumber)" class="font-color click">+55 (71)
                            <strong>3646-3200</strong>
                        </div>
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp"
                                src="../../../../assets/imgs/svgs/telephone-icon-transparent-bg.svg" alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.phoneNumber)" class="font-color click">+55 (71)
                            <strong>3646-3200</strong>
                        </div>
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp"
                                src="../../../../assets/imgs/svgs/instagram-icon-transparent-bg.svg" alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.instagram)" class="font-color click">
                            @<strong>institutoenso</strong></div>
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                        <div class="mb-icon">
                            <img class="bi font-color bi-whatsapp"
                                src="../../../../assets/imgs/svgs/location-icon-transparent-bg.svg" alt="">
                        </div>
                        <div (click)="redirectexternalToRoute(envi.mapGeo)" class="font-color click text-center">
                            <strong>Vitória Boulevard <br> Av. Sete de Setembro, 1839</strong><br>Vitória,
                            Salvador - BA, 40080-002
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 border-line-top mb-2">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center mt-2">
                        <div class="font-color"><strong>Mapa do site</strong></div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeHome)">HOME</div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeInstituto)">O INSTITUTO</div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeProfissionais)">PROFISSIONAIS
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <div class="font-color click" (click)="redirectToRoute(envi.routeProcedimentos)">PROCEDIMENTOS
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <div class="font-color click" (click)="redirectToRoute(envi.routePortalPaciente)">PORTAL DO
                            PACIENTE
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <div class="font-color click" (click)="redirectexternalToRoute(envi.whatsappNumber)">AGENDAMENTO
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <div class="font-color click" (click)="redirectexternalToRoute(envi.whatsappNumber)">CONTATO
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 border-line-top mt-2">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <div>
                            <div class="font-color text-center">
                                <div><strong>Responsável Técnico</strong></div>
                                <div>Dr. Almiro Vieira de Melo</div>
                                <div>CRM 15148 | RQE 18782</div>
                            </div>
                        </div>

                        <!-- <div>
                            <div class="mb-icon">
                                <img class="bi font-color bi-person"
                                    src="../../../../assets/imgs/svgs/person-icon-transparent-bg.svg" alt="">
                            </div>
                        </div> -->
                    </div>

                    <div class="col-12 d-flex justify-content-center size-of-col">
                        <div class="font-color text-center">
                            <div><strong>Horário de funcionamento:</strong></div>
                            <div>Segunda a sexta: <strong>07h às 19h</strong></div>
                            <div>Sábado: <strong>07h às 13h</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-color-below">
        <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-center font-color-primary text-center">
                Instituto Enso® - Todos os direitos reservados - CNPJ 29.147.052/0001-77
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center font-color-primary">
                Agência Lutz Brasil | GN Design Studio
            </div>
        </div>
        <div class="row pt">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="font-color click custom-button border-btn"
                    (click)="redirectexternalToRoute(envi.urlCanalDenuncia)">CANAL DE DENÚNCIA</div>
            </div>
        </div>
        <div class="row pt">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="font-color click custom-button border-btn"
                    (click)="redirectexternalToRoute(envi.urlCanalRequisicaoTitular)">CANAL DE REQUISIÇÃO DO TITULAR
                </div>
            </div>
        </div>
    </div>
</ng-template>