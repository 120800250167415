export const environment = {
    production: true,
    apiKey: 'some-PRODUCTION-@P!_K$y-012345678',
    whatsappNumber: 'https://api.whatsapp.com/send?phone=557136463200',
    phoneNumber: 'tel:557136463200',
    instagram: 'https://www.instagram.com/institutoenso/',
    mapGeo: 'https://www.google.com/maps/place/Av.+Sete+de+Setembro,+1839+-+Vit%C3%B3ria,+Salvador+-+BA,+40080-002/@-12.9899663,-38.5237062,17z/data=!3m1!4b1!4m6!3m5!1s0x716048882d441d3:0xd684684bba421206!8m2!3d-12.9899663!4d-38.5237062!16s%2Fg%2F11f1012y5k?entry=ttu',
    routeHome: 'public/home',
    routeInstituto: 'public/instituto',
    routeProfissionais: 'public/profissionais',
    routeProcedimentos: 'public/procedimentos',
    routePortalPaciente: 'public/portal-paciente',
    login: 'public/login',
    consultasAgendadas: 'private/consultas-agendadas',
    contratoReembolso: 'private/contrato-de-reembolso',
    notaFiscal: 'private/nota-fiscal',
    routeAgendamento: '**',
    routeContato: '**',
    urlLabs: 'https://ensoresultado.labchecap.com.br/login',
    urlCanalDenuncia: 'https://app.protegon.com.br/#/external_incident_complaint/e43e0162',
    urlCanalRequisicaoTitular: 'https://app.protegon.com.br/#/external_request/82764410',
    ambiente: 'environment.prod',
    apiUrl: 'https://apigateway-ext.labchecap.com.br'
};
