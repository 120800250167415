<div *ngIf="!isMobileScreen; else mobileLayout">

    <div class="navbar-background">
        <div class="d-flex justify-content-center align-items-center">
            <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeInstituto}">
                <a class="nav-link font-color-primary size-options-navbar click"
                    (click)="redirectToRoute(envi.routeInstituto)">O
                    INSTITUTO</a>
            </div>
            <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeProfissionais}">
                <a class="nav-link font-color-primary size-options-navbar click"
                    (click)="redirectToRoute(envi.routeProfissionais)">PROFISSIONAIS</a>
            </div>
            <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeProcedimentos}">
                <a class="nav-link font-color-primary size-options-navbar click"
                    (click)="redirectToRoute(envi.routeProcedimentos)">PROCEDIMENTOS</a>
            </div>
            <div class="padding-navbar-options-icon">
                <a (click)="redirectToRoute(envi.routeHome)">
                    <img class="size-image-logo" src="../../../../assets/imgs/LOGOTIPOS ENSŌ-01-2.png"
                        alt="imagem-logo">
                </a>
            </div>
            <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routePortalPaciente}">
                <a class="nav-link float-end font-color-primary size-options-navbar click"
                    (click)="redirectToRoute(envi.routePortalPaciente)">PORTAL DO
                    PACIENTE</a>
            </div>
            <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeAgendamento}">
                <a class="nav-link float-end font-color-primary size-options-navbar click"
                    (click)="redirectexternalToRoute(envi.whatsappNumber)">AGENDAMENTO</a>
            </div>
            <div class="padding-navbar-options" [ngClass]="{'apply-icon': option === envi.routeContato}">
                <a class="nav-link float-end font-color-primary size-options-navbar click"
                    (click)="redirectexternalToRoute(envi.whatsappNumber)">CONTATO</a>
            </div>
        </div>
    </div>

</div>




<ng-template #mobileLayout>

    <nav class="navbar navbar-expand-lg navbar-light navbar-background">
        <div class="container-fluid">

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <a class="navbar-brand mx-auto" (click)="redirectToRoute(envi.routeHome)">
                <img class="size-image-logo-mobile" src="../../../../assets/imgs/LOGOTIPOS ENSŌ-01-2.png"
                    alt="imagem-logo">
            </a>

            <div class="collapse navbar-collapse" id="navbarNav">
                <div class="navbar-nav ml-auto">
                    <a class="nav-link margin-t" [ngClass]="option === envi.routeInstituto ? 'apply-icon-mobile': 'no-apply-icon-mobile'"
                        (click)="redirectToRoute(envi.routeInstituto)">O INSTITUTO</a>
                    <a class="nav-link" [ngClass]="option === envi.routeProfissionais ? 'apply-icon-mobile': 'no-apply-icon-mobile'"
                        (click)="redirectToRoute(envi.routeProfissionais)">PROFISSIONAIS</a>
                    <a class="nav-link" [ngClass]="option === envi.routeProcedimentos ? 'apply-icon-mobile': 'no-apply-icon-mobile'"
                        (click)="redirectToRoute(envi.routeProcedimentos)">PROCEDIMENTOS</a>
                    <a class="nav-link" [ngClass]="option === envi.routePortalPaciente ? 'apply-icon-mobile': 'no-apply-icon-mobile'"
                        (click)="redirectToRoute(envi.routePortalPaciente)">PORTAL DO PACIENTE</a>
                    <a class="nav-link" [ngClass]="option === envi.routeAgendamento ? 'apply-icon-mobile': 'no-apply-icon-mobile'"
                        (click)="redirectexternalToRoute(envi.whatsappNumber)">AGENDAMENTO</a>
                    <a class="nav-link" [ngClass]="option === envi.routeContato ? 'apply-icon-mobile': 'no-apply-icon-mobile'"
                        (click)="redirectexternalToRoute(envi.whatsappNumber)">CONTATO</a>
                </div>
            </div>
        </div>
    </nav>

</ng-template>