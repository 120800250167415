<button type="button" class="close d-flex justify-content-end" (click)="closeModal()">X</button>

<div class="modal-header d-flex justify-content-center">
    <p class="modal-title font-color-primary">Faça login para acessar seus dados</p>
</div>
<div class="modal-body">
    <form>
        <div class="mb-3 text-center">
            <input type="email" class="form-control custom-input" id="email" placeholder="Digite seu e-mail">
        </div>
        <div class="mb-3 text-center">
            <input type="password" class="form-control custom-input" id="password" placeholder="Digite sua senha">
        </div>
        <div class="text-center">
            <button type="button" class="button btn btn-primary">
                ENTRAR
            </button>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Fechar</button>
</div>