import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messageSubject = new BehaviorSubject<any>(null);

  sendMessage(message: any): void {
    this.messageSubject.next(message);
  }

  showSuccessMessage(message: string): void {
    this.sendMessage({
      success: true,
      message: message,
    });
  }

  showFailureMessage(message: string): void {
    this.sendMessage({
      success: false,
      message: message,
    });
  }

  getMessage(): Observable<any> {
    return this.messageSubject.asObservable();
  }
}
