import { Component, ElementRef, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Collapse } from 'bootstrap';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  envi = environment;
  option: string;
  isMobileScreen: boolean = false;
  private isNavbarOpen: boolean = false;

  constructor(private router: Router, private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    window.addEventListener('resize', () => {
      this.checkScreenWidth();
    });

    this.checkScreenWidth();

    this.router.events.subscribe((val: any) => {
      if (val?.url) {
        this.option = val.url.substring(1);
        if (this.option === '**') {
          this.option = '';
        }
      }
    });
  }

  private checkScreenWidth() {
    this.isMobileScreen = window.innerWidth <= 991;
  }

  toggleNavbar() {
    const navbar = this.elementRef.nativeElement.querySelector('.navbar-collapse');
    if (navbar) {
      if (this.isNavbarOpen) {
        this.renderer.removeClass(navbar, 'show');
        this.isNavbarOpen = false;
      } else {
        this.renderer.addClass(navbar, 'show');
        this.isNavbarOpen = true;
      }
    }
  }

  redirectToRoute(route: string) {
    if (route) {
      this.toggleNavbar();

      this.router.navigate([route]);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);

          const navbar = this.elementRef.nativeElement.querySelector('.navbar-collapse');
          this.renderer.removeClass(navbar, 'show');
          this.isNavbarOpen = false;
        }
      });
    }
  }

  redirectexternalToRoute(link: string) {
    if (link) {
      window.open(link);
    }
  }

}
