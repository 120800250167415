import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../services/general/message.service';
import { AuthService } from '../services/general/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private messageService: MessageService,
        private authService: AuthService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.authService.clearUserData();
                    this.messageService.sendMessage({
                        success: false,
                        errors: ['Por favor, faça login novamente, seu acesso expirou.']
                    });
                    this.router.navigate(['public/portal-paciente']);
                }
                return throwError(error);
            })
        );
    }
}
