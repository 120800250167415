import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl + '/portalpaciente/api';

  constructor(private http: HttpClient) { }

  login(credentials: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/Login', credentials);
  }

  setUserData(token: string, nome: string, cpf: string, dataNascimento: string, pacreg: string): void {
    localStorage.setItem('token', `Bearer ${token}`);
    localStorage.setItem('nome', nome);
    localStorage.setItem('cpf', cpf);
    localStorage.setItem('dataNascimento', dataNascimento);
    localStorage.setItem('pacreg', pacreg);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getNome(): string | null {
    return localStorage.getItem('nome');
  }

  getCPF(): string | null {
    return localStorage.getItem('cpf');
  }

  getDataNascimento(): string | null {
    return localStorage.getItem('dataNascimento');
  }

  getPacreg(): string | null {
    return localStorage.getItem('pacreg');
  }
  
  clearUserData(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('nome');
    localStorage.removeItem('cpf');
    localStorage.removeItem('dataNascimento');
    localStorage.removeItem('pacreg');    
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.getToken()
    });
  }

}
