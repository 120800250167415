import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/core/services/general/message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent  implements OnDestroy {
  messages: string[] = [];
  isSuccess: boolean = true;
  displayMessage: boolean = false;

  private messageSubscription: Subscription;

  constructor(private messageService: MessageService) {
    this.messageSubscription = this.messageService.getMessage().subscribe((message) => {
      if (message && message.success !== false) {
        this.isSuccess = true;
        this.messages = [message.message];
      } else if (message && message.success === false && message.errors) {
        this.isSuccess = false;
        this.messages = message.errors.join('\n').split('\n').filter(Boolean);
      }

      this.displayMessage = !!message;

      if (this.displayMessage) {
        setTimeout(() => {
          this.closeMessage();
        }, 8000);
      }
    });
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }

  closeMessage(): void {
    this.messageService.sendMessage(null);
    this.displayMessage = false;
  }
}
