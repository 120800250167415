import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PopUpNewsletterComponent } from './components/pop-up-newsletter/pop-up-newsletter.component';
import { PortalPacienteBannerComponent } from './components/portal-paciente-banner/portal-paciente-banner.component';
import { MessageComponent } from './components/message/message.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    PopUpNewsletterComponent,
    PortalPacienteBannerComponent,
    MessageComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    PopUpNewsletterComponent,
    PortalPacienteBannerComponent,
    MessageComponent
  ]
})
export class SharedModule { }
