import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  envi = environment;
  isMobileScreen: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    window.addEventListener('resize', () => {
      this.checkScreenWidth();
    });

    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    this.isMobileScreen = window.innerWidth <= 991;
  }

  redirectToRoute(route: string) {
    if (route) {
      this.router.navigate([route]);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });
    }
  }

  redirectexternalToRoute(link: string) {
    if (link) {
      window.open(link);
    }
  }
}
