import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-paciente-banner',
  templateUrl: './portal-paciente-banner.component.html',
  styleUrls: ['./portal-paciente-banner.component.css']
})
export class PortalPacienteBannerComponent {

}
