import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-pop-up-newsletter',
  templateUrl: './pop-up-newsletter.component.html',
  styleUrls: ['./pop-up-newsletter.component.css']
})
export class PopUpNewsletterComponent {
  constructor(public bsModalRef: BsModalRef) {}

  closeModal() {
    this.bsModalRef.hide();
  }
}
